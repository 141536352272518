<template>
  <div>
    <a-card title="供应商信息" class="cardMiniBox" size="small">
      <p class="cardContent">管理应用内供应商信息</p>
      <p class="cardP">
        <a type="text" style="color: #1890ff" @click="openList()">供应商管理</a>
      </p>
    </a-card>
    <a-drawer
      width="900"
      placement="right"
      v-model:visible="state.visible"
      @close="close()"
      :closable="false"
      title="供应商管理"
    >
      <a-table
        :columns="state.shipperList"
        rowKey="id"
        :scroll="{ y: 800 }"
        :data-source="state.tableList"
        :pagination="false"
        :row-selection="{
          selectedRowKeys: state.joinSelectKeys,
          onChange: onSelectJoin,
        }"
      >
        <template #bodyCell="{ column, record }">
          <template v-if="column.key === 'status'">
            <el-switch disabled v-model="record.status" />
          </template>
        </template>
      </a-table>
    </a-drawer>
  </div>
</template>
<script setup>
import { ref, defineProps } from "vue";
import axios from "@/common/axios";
import api from "@/common/api/api";
import { useStore } from "vuex";
const store = useStore();
const state = ref({
  visible: false,
  editVisible: false,
  addVisible: false,
  shipperList: [
    {
      title: "供应商名称",
      dataIndex: "supplierName",
      key: "supplierName",
      width: 100,
    },
    {
      title: "供应商code",
      dataIndex: "supplierCode",
      key: "supplierCode",
      width: 100,
    },
    {
      title: "合作模式",
      dataIndex: "bizMode",
      key: "bizMode",
      width: 100,
    },
    {
      title: "供应商国家",
      dataIndex: "country",
      key: "country",
      width: 120,
    },
    {
      title: "状态",
      dataIndex: "status",
      key: "status",
      width: 80,
    },
  ],
  tableList: [],
  joinSelectKeys: [],
  infoTitle: "供应商",
  formInfo: {}, //新增编辑
  labelCol: { span: 6 },
  wrapperCol: { span: 16 },
  codeDisabled: false,
});
const props = defineProps({
  parentInfo: Object,
});
console.log("props", props.parentInfo);
const openList = (value) => {
  console.log(value);
  state.value.visible = true;
  let data = {
    merchantId: store.getters["user/merchantId"],

    all: true,
    merchantAppId: props.parentInfo.id,
  };
  axios.post(api.supplierList, data).then((res) => {
    console.log(res);
    state.value.tableList = res;
  });
};

const onSelectJoin = (value) => {
  console.log(value);
  state.value.joinSelectKeys = value;
};

const close = () => {
  state.value.visible = false;
};
</script>
<style lang="scss" scoped>
.ant-form {
  :deep(.ant-form-item) {
    width: 50%;
    margin-bottom: 0;
    display: flex;
    justify-content: space-between;
    height: 56px;
    margin-right: 0;
    padding-right: 10px;
  }
}
.ant-form {
  :deep(.ant-form-item-with-help) {
    margin-bottom: 0;
  }
}
.ant-form {
  :deep(.ant-form-item-control-input-content) {
    display: flex;
  }
}
</style>
