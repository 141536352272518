import { message } from "ant-design-vue";
export default function (data) {
  let url = data;
  let oInput = document.createElement("input");
  oInput.value = url;
  document.body.appendChild(oInput);
  oInput.select(); // 选择对象;
  document.execCommand("Copy"); // 执行浏览器复制命令
  message.success("复制成功");
  oInput.remove();
}
