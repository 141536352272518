//components/Icon-svg
<template>
  <p style="line-height: 30px; margin-bottom: 16px">
    <a-row>
      <a-col
        :style="`width:${colOneWidth}px;display: inline-block;color: black`"
        >{{ title }}</a-col
      >
      <a-col v-if="url.length > 0"
        ><img :src="url" style="width: 70px; height: 70px" alt=""
      /></a-col>
      <a-col v-else-if="arr.length > 0">
        <p v-for="item in arr" :key="item">{{ item }}</p>
      </a-col>
      <a-col
        v-else-if="title.includes('状态')"
        :style="text == '启用' ? 'color:#1890ff' : 'color:#f81d22'"
        >{{ text }}</a-col
      >
      <a-col v-else >{{ text }}</a-col>
      <a-col>
        <slot></slot>
      </a-col>
    </a-row>
  </p>
</template>

<script>
import { reactive, toRefs } from "vue";
export default {
  name: "icon-svg",
  props: {
    title: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: false,
    },
    colOneWidth: {
      type: String,
      required: false,
      default: "120",
    },
    url: {
      type: String,
      required: false,
      default: "",
    },
    arr: {
      type: Array,
      required: false,
      default: () => {
        return [];
      },
    },
  },
  setup(props) {
    let data = reactive({
      title: props.title,
      text: props.text,
      colOneWidth: props.colOneWidth,
      img: props.url,
      arr: props.arr,
    });
    return {
      ...toRefs(data),
    };
  },
};
</script>
