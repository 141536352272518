<template>
  <div>
    <a-card title="快递信息" class="cardMiniBox" size="small">
      <p class="cardContent">管理应用内快递信息</p>
      <p class="cardP">
        <a type="text" style="color: #1890ff" @click="openList()">快递管理</a>
      </p>
    </a-card>
    <a-drawer
      width="900"
      placement="right"
      v-model:visible="state.visible"
      @close="close()"
      :closable="false"
      title="快递管理"
    >
      <!-- <a-row style="margin-bottom: 10px" :gutter="16">

        <a-col
          ><a-button @click="addList" style="margin-left: 10px" type="primary"
            >新增快递</a-button
          ></a-col
        >
      </a-row> -->
      <a-table
        :columns="state.warehouseList"
        rowKey="id"
        :scroll="{ y: 800 }"
        :data-source="state.tableList"
        :pagination="false"
        :row-selection="{
          selectedRowKeys: state.joinSelectKeys,
          onChange: onSelectJoin,
        }"
      >
        <template #bodyCell="{ column, record }">
          <template v-if="column.key === 'status'">
            <span>
              <el-popconfirm
                confirm-button-text="确认"
                cancel-button-text="取消"
                title="确认修改应用状态吗"
                @confirm="saveInfo(record)"
                @cancel="cancelSwitch(record)"
              >
                <template #reference>
                  <el-switch
                    disabled
                    @click="clickInfo(record)"
                    v-model="record.status"
                  />
                </template>
              </el-popconfirm>
            </span>
          </template>
          <template v-if="column.key === 'action'">
            <a @click="accountManage(record)">账号管理</a>
          </template>
        </template>
      </a-table>
      <!-- 编辑快递店铺货主 -->
      <a-drawer
        v-model:visible="state.accountVisible"
        title="账号管理"
        width="820"
        :closable="false"
      >
        <a-table
          :columns="state.accountList"
          rowKey="id"
          :scroll="{ y: 800 }"
          :data-source="state.accountInfo.tmsAccountList"
          :pagination="false"
        >
          <template #bodyCell="{ column, record }">
            <template v-if="column.key === 'status'">
              <span>
                <el-popconfirm
                  confirm-button-text="确认"
                  cancel-button-text="取消"
                  title="确认修改应用状态吗"
                  @confirm="saveInfo(record, 'status')"
                  @cancel="cancelSwitch(record)"
                >
                  <template #reference>
                    <el-switch
                      @click="clickInfo(record)"
                      v-model="record.status"
                    />
                  </template>
                </el-popconfirm>
              </span>
            </template>
            <template v-if="column.key === 'action'">
              <a @click="editList(record)">编辑</a>
            </template>
          </template>
        </a-table>
        <a-drawer
          v-model:visible="state.editVisible"
          title="账号编辑"
          width="720"
          :closable="false"
        >
          <a-form
            style="margin: 0 auto"
            ref="merchantRef"
            :model="state.formInfo"
            :label-col="state.labelCol"
            :wrapper-col="state.wrapperCol"
            layout="inline"
            @finish="saveInfo"
          >
            <a-form-item
              name="name"
              :rules="[
                {
                  required: true,
                  message: `请输入账号名称`,
                  trigger: 'blur',
                },
              ]"
              :label="`账号名称`"
            >
              <a-input
                v-model:value="state.formInfo.name"
                :placeholder="`请输入账号名称`"
              />
            </a-form-item>

            <a-form-item
              :rules="[
                {
                  required: true,
                  message: `请输入账号code`,
                  trigger: 'blur',
                },
              ]"
              name="code"
              :label="`账号code`"
            >
              <a-input
                v-model:value="state.formInfo.code"
                :placeholder="`请输入账号code`"
              />
            </a-form-item>
            <a-form-item :wrapper-col="{ offset: 8, span: 16 }">
              <a-button type="primary" html-type="submit">保存</a-button>
            </a-form-item>
          </a-form>
        </a-drawer>
      </a-drawer>
    </a-drawer>
  </div>
</template>
<script setup>
import { ref, defineProps } from "vue";

import axios from "@/common/axios";
import api from "@/common/api/api";
import { useStore } from "vuex";
import { message } from "ant-design-vue";
const store = useStore();
const state = ref({
  visible: false,
  editVisible: false,
  addVisible: false,
  warehouseList: [
    {
      title: "快递名称",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "快递code",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "快递平台",
      dataIndex: "platform",
      key: "platform",
    },
    {
      title: "状态",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "操作",
      dataIndex: "action",
      key: "action",
    },
  ],
  accountList: [
    {
      title: "账号名称",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "账号code",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "状态",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "操作",
      dataIndex: "action",
      key: "action",
    },
  ],
  tableList: [],
  joinSelectKeys: [],
  infoTitle: "快递",
  formInfo: {}, //新增编辑
  accountInfo: {},
  labelCol: { span: 6 },
  wrapperCol: { span: 16 },
});
const props = defineProps({
  parentInfo: Object,
});
console.log("props", props.parentInfo);
const openList = (value) => {
  console.log(value);
  state.value.visible = true;
  let data = {
    merchantId: store.getters["user/merchantId"],
    // saasAppType: value.appType,
    merchantAppId: props.parentInfo.id,
    needAccount: true,
  };
  axios.post(api.tmsShippingList, data).then((res) => {
    state.value.tableList = res;
  });
};
const accountManage = (value) => {
  state.value.accountVisible = true;
  state.value.accountInfo = value;
};
const editList = (value) => {
  state.value.editVisible = true;
  state.value.formInfo = { ...value };
  state.value.recordInfo = value;
};
// const addList = (value) => {
//   state.value.editVisible = true;
//   state.value.recordInfo = {};
//   console.log(value);
// };
const onSelectJoin = (value) => {
  console.log(value);
  state.value.joinSelectKeys = value;
};
const saveInfo = (record, type) => {
  console.log(record);
  // 有record 代表修改状态
  if (record) {
    if (type == "status") {
      state.value.accountInfo.tmsAccountList.forEach((item) => {
        if (item.name == record.name) {
          item.status = !record.status;
        }
      });
    }

    state.value.formInfo = { ...record };
    state.value.formInfo.id = state.value.recordInfo.id;
  } else {
    state.value.formInfo.status = true;
  }
  state.value.formInfo.merchantId = store.getters["user/merchantId"];
  state.value.formInfo.merchantAppId = props.parentInfo.id;
  state.value.formInfo.tmsShippingId = state.value.accountInfo.id;
  let data = {
    tmsAccount: {
      ...state.value.formInfo,
    },
  };
  axios.post(api.tmsAccountSave, data).then((res) => {
    message.success("操作成功");
    console.log(res);
    console.log(state.value.accountInfo);
    state.value.accountInfo.tmsAccountList.forEach((item) => {
      if (item.id == res.id) {
        item.name = state.value.formInfo.name;
        item.code = state.value.formInfo.code;
        item.status = state.value.formInfo.status;
      }
    });
    state.value.editVisible = false;
    openList();
  });
};
const close = () => {
  state.value.visible = false;
};
</script>
<style lang="scss" scoped>
.ant-form {
  :deep(.ant-form-item) {
    width: 50%;
    margin-bottom: 0;
    display: flex;
    justify-content: space-between;
    height: 56px;
    margin-right: 0;
    padding-right: 10px;
  }
}
.ant-form {
  :deep(.ant-form-item-with-help) {
    margin-bottom: 0;
  }
}
.ant-form {
  :deep(.ant-form-item-control-input-content) {
    display: flex;
  }
}
</style>
